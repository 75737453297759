
<template>
    <div class="stepsdemo-content">
        <Card class="py-10">
            <template v-slot:title>
              <h2 class="text-center mx-auto w-full lg:w-3/4 xl:w-1/2">
                Detallado de la tutela
              </h2>
            </template>
            <template v-slot:content>
              <div class="p-fluid p-2 mx-auto w-full lg:w-3/4 xl:w-1/2 flex justify-center items-center gap-4">
                <div class="p-fluid formgrid grid w-full">
                    <div class="grid">
                    <div class="flex flex-col w-full">
                        <label class="j-label">Numero de radicado</label>
                        <div class="grid grid-cols-1">
                        <div class="p-field">
                            <InputMask mask="9999-99999" v-model="model.numRadicado" class="p-inputtext w-full" placeholder="0000-00000" />
                            <MessageError :text="errors.numRadicado"/>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="flex flex-col lg:grid lg:grid-cols-2 gap-4">
                    <div class="grid">
                        <div class="flex flex-col">
                        <label class="j-label">Fecha de auto</label>
                        <div class="grid grid-cols-1">
                            <div class="p-field">
                            <input type="date" class="p-inputtext w-full" placeholder="mm-dd-yyyy" v-model="model.fechaExpedicion" >
                            <MessageError :text="errors.fechaExpedicion"/>
                            </div>
                        </div>
                        </div>
                        <div class="flex flex-col">
                        <label class="j-label">Recepción de la notificación</label>
                        <div class="grid grid-cols-2 gap-4">
                            <div class="p-field">
                            <input type="date" class="p-inputtext w-full" placeholder="mm-dd-yyyy" v-model="model.fechaRecepcion" >
                            <MessageError :text="errors.fechaRecepcion"/>
                            </div>
                            <div class="p-field">
                            <input type="time" class="p-inputtext w-full" v-model="model.horaRecepcion">
                            <MessageError :text="errors.horaRecepcion"/>
                            </div>
                        </div>
                        </div>
                        <div class="flex flex-col">
                        <label class="j-label">Vencimiento de terminos</label>
                        <div class="grid grid-cols-2 gap-4">
                            <div class="p-field">
                            <input type="date" class="p-inputtext w-full" placeholder="mm-dd-yyyy" v-model="model.fechaVencimiento" >
                            <MessageError :text="errors.fechaVencimiento"/>
                            </div>
                            <div class="p-field">
                            <input type="time" class="p-inputtext w-full" v-model="model.horaVencimiento">
                            <MessageError :text="errors.horaVencimiento"/>
                            </div>
                        </div>
                        </div>
                        <div class="flex flex-col">
                        <label class="j-label">Medida provisional</label>
                        <div class="flex mb-2">
                            <label class="j-label my-auto">no</label>
                            <InputSwitch class="mx-2" v-model="model.provisonalidad" />
                            <label class="j-label my-auto">si</label>
                        </div>
                        </div>
                        <div class="flex flex-col">
                        <div class="grid grid-cols-2 gap-4">
                            <div class="p-field">
                            <input type="date" class="p-inputtext w-full" placeholder="mm-dd-yyyy" v-model="model.provisonalidadFecha" >
                            <MessageError :text="errors.provisonalidadFecha"/>
                            </div>
                            <div class="p-field">
                            <input type="time" class="p-inputtext w-full" v-model="model.provisonalidadHora">
                            <MessageError :text="errors.provisonalidadHora"/>
                            </div>
                        </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="flex flex-col">
                            <label class="j-label">
                            Observaciones
                            </label>
                            <div class="p-field">
                                <textarea name="textarea"
                                        v-model="model.observaciones"
                                        class="p-inputtext w-full"
                                        rows="9"
                                        cols="50"/>
                                <MessageError :text="errors.observaciones"/>
                            </div>
                        </div>
                        <div class="mb-2">
                        <div class="px-2 w-full mt-2 mb-5">
                          <div style="max-height: 150px; overflow-y: auto;">
                            <div class="flex justify-between items-center" v-for="(item, key) in model.soportesJuridicos" :key="key">
                              <div class="flex">
                                <button @click="verPdf(item.urlPath)">
                                  <i class="pi pi-file-pdf mr-2 text-black cursor-pointer"></i>
                                </button>
                                <p>{{ item.fileName }}</p>
                              </div>
                              <div class="">
                                <button @click="quitarPdf(item)"><i class="pi pi-trash mr-2 text-red-600"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <button label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                            Agregar nuevo soporte
                          </button>
                        </div>
                        <div class="flex flex-col">
                          <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                            <div>
                              <div>
                                <label class="text-sm" for="titulo">Titulo</label>
                                <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                                      class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                              </div>
                              <div class="my-2">
                                <label class="text-sm">Adjunte el documento</label>
                                <div class="mt-2">
                                  <FilePond ref="pond"/>
                                </div>
                              </div>
                            </div>
                            <template class="text-center" #footer>
                              <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
                            </template>
                          </Dialog>
                        </div>
                        <MessageError :text="errors.soportesJuridicos" class="w-full"/>
                        </div>
                        <div class="flex flex-col">
                        <div class="p-field w-full">
                            <Dropdown class="w-full"
                                    :options="listUsuarios"
                                    option-label="fullName"
                                    option-value="id"
                                    placeholder="Seleccione responsable de la tutela"
                                    @change="setResponsable"
                                    v-model="model.userResponsableId"
                            />
                            <MessageError :text="errors.userResponsableId"/>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
              </div>
            </template>
            <template v-slot:footer>
              <div class="w-full p-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
                <div class="flex w-full justify-center md:justify-between px-2">
                    <Button label="Regresar" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Guardar" @click="nextPage()" icon="pi pi-angle-right" iconPos="right" />
                </div>
              </div>
            </template>
        </Card>
    </div>
</template>

<script>
import storeTutela from '@/apps/pharmasan/juridica/procesosJuridicos/store/tutela.store'
import StoreUsuarios from '@/apps/pharmasan/juridica/procesosJuridicos/store/usuarios.store'
import { computed, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'
import { useField, useForm } from 'vee-validate'
import dayjs from 'dayjs'
import { useToast } from 'primevue/usetoast'
import FilePond from '../../../../uploadFilePoundNew/index.vue'
import FilePondStore from '../../../../../store/filepond.store'
import PdfService from '../../../../../services/pdf.service'
export default {
  name: 'DetalleTutela',
  components: {
    FilePond
  },
  setup () {
    const router = useRouter()
    const toast = useToast()
    const displayMaximizable = ref(false)
    const adjuntos = ref([])
    const title = ref('')
    const _PdfService = new PdfService()
    const pond = ref()
    /** state **/
    const schema = yup.object({
      numRadicado: yup.string().nullable().required('El campo es obligatorio').label(''),
      fechaExpedicion: yup.string().nullable().required('El campo es obligatorio').label(''),
      fechaRecepcion: yup.string().nullable().required('El campo es obligatorio').label(''),
      horaRecepcion: yup.string().nullable().required('El campo es obligatorio').label(''),
      fechaVencimiento: yup.string().nullable().required('El campo es obligatorio').label(''),
      horaVencimiento: yup.string().nullable().required('El campo es obligatorio').label(''),
      observaciones: yup.string().nullable().required('El campo es obligatorio').label(''),
      userResponsableId: yup.number().nullable().required('El campo es obligatorio').label(''),
      soportesJuridicos: yup.array().of(
        yup.object().shape(
          {
            urlPath: yup.string().nullable().required('El campo es obligatorio').label(''),
            fileName: yup.string().nullable().required('El campo es obligatorio').label('')
          }
        )
      ).min(3, 'Debe ingresar al menos 3 archivos')
    })
    const { errors, values: model, handleSubmit, handleReset } = useForm({
      validationSchema: schema
    })
    useField('numRadicado', null, {
      initialValue: null
    })
    useField('fechaExpedicion', null, {
      initialValue: null
    })
    useField('fechaRecepcion', null, {
      initialValue: dayjs().format('YYYY-MM-DD')
    })
    useField('horaRecepcion', null, {
      initialValue: dayjs().format('hh:mm')
    })
    useField('fechaVencimiento', null, {
      initialValue: null
    })
    useField('horaVencimiento', null, {
      initialValue: null
    })
    useField('observaciones', null, {
      initialValue: null
    })
    useField('provisonalidad', null, {
      initialValue: false
    })
    useField('provisonalidadFecha', null, {
      initialValue: false
    })
    useField('provisonalidadHora', null, {
      initialValue: false
    })
    useField('userResponsableId', null, {
      initialValue: null
    })
    useField('soportesJuridicos', null, {
      initialValue: []
    })
    /** ref **/
    const nombre1 = ref('')
    const nombre2 = ref('')
    /** data **/
    /** computed **/
    const adjunto = computed(
      () => FilePondStore.getters.adjunto
    )
    const tutela = computed(() => storeTutela.getters._tutela)
    const listUsuarios = computed(() => StoreUsuarios.getters.usuarios)
    /** methodos **/
    const nextPage = handleSubmit((values) => {
      if (!values.provisonalidad) {
        delete values.provisonalidadFecha
        delete values.provisonalidadHora
      }
      storeTutela.commit('setDetalleTutela', values)
      storeTutela.dispatch('onSave').then(() => {
        toast.add({ severity: 'success', summary: 'Exíto', detail: 'registro exitoso', life: 3000 })
        router.push({ name: 'pharmasan.administrativa.juridica.tutelas.listado' })
        handleReset()
      })
    })
    const verPdf = (path) => {
      _PdfService.ver({ path })
    }
    const openMaximizable = () => {
      displayMaximizable.value = true
    }
    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        model.soportesJuridicos.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }
    const prevPage = () => {
      router.push('buscar-juzgado', { pageIndex: 1 })
    }
    const setResponsable = ({ value }) => {
      storeTutela.commit('setResponsableId', value)
    }
    const resetTutela = () => {
      handleReset()
      storeTutela.commit('setReset')
      router.push({ name: 'pharmasan.administrativa.juridica.tutelas' })
    }
    /** hook **/
    onMounted(() => {
      StoreUsuarios.dispatch('getAll')
    })
    return {
      tutela,
      errors,
      model,
      listUsuarios,
      setResponsable,
      resetTutela,
      nombre1,
      nombre2,
      nextPage,
      prevPage,
      closeMaximizable,
      openMaximizable,
      verPdf,
      adjuntos,
      displayMaximizable,
      title,
      pond
    }
  }
  }
</script>
<style lang="scss" scoped>
 .j-label{
   color: #354357;
   font-size: 16px;
   opacity: 0.83;
 }
 .actual-btn {
   background-color: #1E40AF;
   color: white;
   padding: 0.5rem;
   font-family: sans-serif;
   border-radius: 0.3rem;
   cursor: pointer;
   width: 130px;
   //margin-top: 1rem;
 }
</style>
